
import { Vue, Component, Watch } from 'vue-property-decorator'
import { AuthorsAPI } from '@/api'
import { PageHeader } from '@/components/page-header'
import {
  Author, AuthorTotalPubs, PublishedAs, CoAuthors,
  Publications, Journals, Series, AuthorSerialsIds, Classifications,
  AuthorExtraInfoComponent, AuthorEdit, getLastNameInitial, AuthorExtraInfo,
  CollabDistanceCard,
} from '@/components/author-page'
import { Card } from '@/components/card'
import { Route } from 'vue-router'

@Component({
  components: {
    PageHeader,
    Card,
    AuthorTotalPubs,
    PublishedAs,
    CoAuthors,
    Publications,
    Journals,
    Series,
    Classifications,
    AuthorExtraInfoComponent,
    AuthorEdit,
    CollabDistanceCard,
  },
})
export default class MRAuthorID extends Vue {
  //
  // REACTIVE PROPERTIES
  //
  authorId = -1
  author: Author | null = null
  serialsIds: AuthorSerialsIds = {
    journalIds: [],
    seriesIds: [],
  }

  loading = true
  // editMode = false

  //
  // WATCHERS
  //

  @Watch('$route', { immediate: true })
  onAuthorIdChanged(to: Route) {
    if (to.params.authorID !== undefined && this.authorId.toString() !== to.params.authorID as string) {
      this.updateAuthorId(+to.params.authorID)
    }
  }

  //
  // COMPUTED PROPERTIES
  //

  get getLastNameInitial() {
    return getLastNameInitial(this.author)
  }

  get journalIdsNonEmpty() {
    return this.serialsIds && this.serialsIds.journalIds && this.serialsIds.journalIds.length > 0
  }

  get seriesIdsNonEmpty() {
    return this.serialsIds && this.serialsIds.seriesIds && this.serialsIds.seriesIds.length > 0
  }

  //
  // LIFE CYCLE HOOKS
  //

  mounted() {
    // this.checkURLParams()
    document.title = 'Author - MathSciNet'
  }

  //
  // EVENT HANDLERS
  //
  onClickCancelEditAuthorProfile() {
    this.removeEditParamFromURL()
  }

  onClickSavedAuthorProfile(authorExtraInfo: AuthorExtraInfo) {
    this.removeEditParamFromURL()

    if (this.author) {
      this.author.extraInfo = authorExtraInfo
    }
  }

  //
  // METHODS
  //

  removeEditParamFromURL() {
    if (this.$route.query.edit === 'true') {
      this.$router.replace({
        name: 'MRAuthorID',
      })
    }
  }

  updateAuthorId(authorId: number) {
    this.authorId = authorId
    this.fetchData()
  }

  checkURLParams() {
    const authorIdNumber = this.$route.query.authorId ? +this.$route.query.authorId : -1
    this.authorId = isNaN(authorIdNumber) ? -1 : authorIdNumber
  }

  async fetchData() {
    await this.fetchAuthor()
  }

  private async fetchAuthor() {
    this.loading = true
    this.author = await AuthorsAPI.getPublicAuthor(this.authorId)
    if (this.author !== null) {
      document.title = `${this.author.profileName} - MathSciNet`
    }
    this.loading = false
  }
}
